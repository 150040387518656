.itemsList {
  position: relative;
  .addButtonSearchWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    //height: 36px;
  }
  .cellAction {
    display: flex;
    align-items: center;

    cursor: pointer;

    .icon {
      cursor: pointer;
    }
    .deleteIcon {
      color: crimson;
    }
    .okicon {
      color: green;
      font-size: 32px;
    }
  }
  .datagridInList {
    a:hover {
      text-decoration: underline;
    }
  }
}

.app.dark {
  .itemsList {
    background-color: #111;
    color: rgb(156, 156, 156);
  }
}
