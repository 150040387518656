@import "../style/variables";

.parangaForSedeBarStart {
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(78, 78, 78);
  -webkit-tap-highlight-color: transparent;
  z-index: 1111;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.dark .parangaForSedeBarStart {
  background-color: rgba(117, 116, 116);
}
.parangaForSedeBarStart.disappear {
  opacity: 0;
}
