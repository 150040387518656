.login .loginLeft {
  background-image: url(/public/assets/dog.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
@media (max-width: 760px) {
  .login .loginLeft {
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
