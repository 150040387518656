.codeSnippet {
  padding: 10px;
  background: #f8f8f8;
  //max-width: 500px;
  box-shadow: 0px 0px 2px 0px rgba(121, 120, 120, 0.75);
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  position: relative;

  .key {
    margin-left: 10px;
    overflow-wrap: break-word;
    color: #a31515//black;
  }
  .value {
    white-space: break-spaces;

    .taken {
      overflow-wrap: break-word;
      color: #f0a50f;
    }
    .taken:before {
      content: "\0022";
    }
    .taken:after {
      content: "\0022";
    }
    .string {
      overflow-wrap: break-word;
      color: #0451a5;
    }
    .boolean {
      color: #098658;
    }
    .number {
      color: #098658;
      font-weight: 500;
    }
  }
}

.dark .codeSnippet {
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  background-color: transparent;
  box-shadow: 0px 0px 2px 0px rgba(138, 138, 138, 0.75);

  .key {
    color: #bd3131;
  }
  .string {
    color: #62adff;
  }
  .boolean {
    color: #0aae72;
  }
  .number {
    color: #0aae72;
  }
}
