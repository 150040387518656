@import "variables";
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: rgb(236, 236, 236);
}
::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199);
  -webkit-border-radius: 5px;
  border: 2px;
}
//https://stackoverflow.com/questions/62162645/change-color-of-chromes-calendar-icon-in-html-date-input
.dark {
  ::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }
  input {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    background: rgb(41, 41, 41);
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(102, 102, 102);
  }
}

// DropDownResponse
.tagsForKeys {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;

  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 3px 9px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);

  gap: 5px;
}

//TableFoRResponse

.single
  .MuiDataGrid-root
  .MuiDataGrid-columnHeaderCheckbox
  .MuiDataGrid-columnHeaderTitleContainer {
  display: none;
}

// .closeIconForTestPreview {
//   color: $lightMainColor;
//   margin-right: 10px;
//   cursor: pointer;
// }
// .closeIconForTestPreview :hover {
//   color: #0a3f74;
// }
// .dark .closeIconForTestPreview {
//   color: #90caf9;
// }
// .dark .closeIconForTestPreview:hover {
//   color: #1976d2;
// }

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
