@import "../../style/variables";

.sidebar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .center::-webkit-scrollbar {
    display: none;
  }
  .center {
    min-height: calc(100vh - ($navbarHeight));
    max-height: calc(100vh - ($navbarHeight));
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

// .sidebar {
//   .center {
//     min-height: calc(100vh - ($navbarHeight));
//     max-height: calc(100vh - ($navbarHeight));
//     overflow-y: auto;
//     /* Hide scrollbar for IE, Edge and Firefox */
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none; /* Firefox */

//   }
// }

// .sidebarMobile {
//   .center {
//     height: calc(100vh - ($navbarHeight + 1px));
//     overflow-y: auto;
//     /* Hide scrollbar for IE, Edge and Firefox */
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none; /* Firefox */

//   }
// }
