.resetpasswordform {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loginWrapper {
    flex: 2;
    //width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loginTitle {
      margin-top: 20px;
      font-weight: 600;
      font-size: 1.2em;
    }

    .loginLogo {
      width: 50px;
      height: 50px;
      background-color: rgb(219, 11, 108);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 50px;
      font-weight: 800;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    .loginIcon {
      width: 32px;
      height: 32px;
      color: white;
    }
    .loginTitle {
      padding-bottom: 15px;
    }

    .loginBox {
      padding: 20px;
      width: 100%;

      display: flex;
      flex-direction: column;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    .spanError {
      font-size: 16px;
      margin-top: 10px;
      color: red;
    }

    .loginButton {
      height: 40px;
      border-radius: 5px;
      border: none;
      background-color: #1775ee;
      color: white;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      margin-top: 30px;
    }

    .loginButton:focus {
      outline: none;
    }

    .loginButton:disabled {
      cursor: not-allowed;
    }

    .loginForgot {
      color: #1775ee;
      margin-top: 10px;
      cursor: pointer;
    }
    .loginForgotMessage {
      font-size: large;

      margin-top: 20px;
    }

    .loginCopyright {
      margin-top: 50px;
      font-size: 14px;
      text-align: center;
    }
  }
}

.app.dark .resetpasswordform {
  color: #999;
  border-color: #333;

  .input {
    border-color: gray;
  }
}
.app.dark .resetpasswordform .loginBox {
  background-color: #111;
}
